import { LoadingButton } from '@mui/lab';
import { Box, Breadcrumbs, Card, Container, Grid, Link, TextField, Typography } from '@mui/material';
import type { FC } from 'react';
import { useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import { Helmet } from 'react-helmet-async';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useLoadingContext } from 'src/contexts/LoadingContext';
import { useSWRConfig } from 'swr';
import { createPromotionBanner, useAllPromotionBanner } from '../../actions/bannerActions';
import { FormInputSelect } from '../../components/common/FormInputSelect';
import { FormInputText } from '../../components/common/FormTextField';
import { UploadDropzone } from '../../components/common/PromotionUploadDropzone';
import useSettings from '../../hooks/useSettings';
import ChevronRightIcon from '../../icons/ChevronRight';

export type PromotionBannerForm = {
  name: string;
  description: string;
  link: string;
  picture: FileWithPath;
  order: number;
};

const BannerCreate: FC = () => {
  const { mutate } = useSWRConfig();
  const { settings } = useSettings();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { isLoading, setLoading } = useLoadingContext();
  const { count } = useAllPromotionBanner({ page: 0, limit: 10 });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    register,
  } = useForm<PromotionBannerForm>();

  const [files, setFiles] = useState<FileWithPath[]>([]);
  register('picture', { required: true });

  const onSubmit: SubmitHandler<PromotionBannerForm> = async data => {
    setLoading(true);
    await createPromotionBanner(mutate, data);
    setLoading(false);
    navigate('/dashboard/promotions-banners');
  };

  const handleDrop = (newFiles: FileWithPath[]): void => {
    setFiles(() => newFiles);
    setValue('picture', newFiles[0], { shouldValidate: true });
  };

  const handleRemove = (): void => {
    setFiles([]);
    setValue('picture', null, { shouldValidate: true });
  };
  return (
    <>
      <Helmet>
        <title>{i18n.t('promotionBannerCreate.title')}</title>
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 4 }}>
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h3">
                {i18n.t('promotionBannerCreate.title')}
              </Typography>
              <Breadcrumbs aria-label="breadcrumb" separator={<ChevronRightIcon fontSize="small" />} sx={{ mt: 1 }}>
                <Link color="textPrimary" component={RouterLink} to="/dashboard" variant="subtitle2">
                  {i18n.t('common.dashboard')}
                </Link>
                <Link color="textPrimary" component={RouterLink} to="/dashboard/promotions-banners" variant="subtitle2">
                  {i18n.t('promotionBannerList.breadCrumb.title')}
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  {i18n.t('promotionBannerCreate.breadCrumb.title')}
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Box sx={{ height: '100%', display: 'flex', alignItems: 'flex-end' }}>
                <LoadingButton
                  loading={isLoading}
                  sx={{ px: 4 }}
                  color="secondary"
                  component={RouterLink}
                  variant="outlined"
                  to="/dashboard/promotions-banners">
                  {i18n.t('button.cancel')}
                </LoadingButton>
                <LoadingButton
                  loading={isLoading}
                  sx={{ px: 4, ml: 2 }}
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                  variant="contained">
                  {i18n.t('button.save')}
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Card sx={{ my: 2 }}>
              <Box sx={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap', p: 2 }}>
                <Box sx={{ flexGrow: 1, flexDirection: 'row', display: 'flex' }}>
                  <FormInputText
                    sx={{ flex: 0.25, mr: 2 }}
                    label={i18n.t('promotionBannerCreate.form.nameInputLabel')}
                    variant="outlined"
                    control={control}
                    name="name"
                    required
                    error={!!errors.name}
                  />
                  <FormInputText
                    sx={{ flex: 0.6, mr: 2 }}
                    label={i18n.t('promotionBannerCreate.form.URLInputLabel')}
                    variant="outlined"
                    control={control}
                    name="link"
                    required
                    error={!!errors.link}
                  />
                  <FormInputSelect
                    sx={{ flex: 0.15 }}
                    label={i18n.t('promotionBannerCreate.form.position')}
                    variant="outlined"
                    control={control}
                    name="order"
                    required
                    error={!!errors.order}
                    defaultValue={1}
                    items={Array.from({ length: count + 1 }, (_, i) => {
                      const value = i + 1;
                      return { value, label: value };
                    })}
                  />
                </Box>
              </Box>
              <Box sx={{ pl: 2, pr: 2, pb: 2 }}>
                <Controller
                  rules={{ required: true }}
                  name="description"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={i18n.t('promotionBannerCreate.form.description')}
                      sx={{ width: '100%' }}
                      required
                      multiline
                      rows={3}
                      maxRows={4}
                      value={field.value || ''}
                    />
                  )}
                />
              </Box>
              <Box />
            </Card>
            <Card sx={{ my: 2 }}>
              <Box sx={{ display: 'flex', p: 2, flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2, justifyContent: 'space-between' }}>
                  <Typography variant="h5" sx={{ fontWeight: 500 }}>
                    {i18n.t('promotionBannerCreate.form.upload.title')}
                  </Typography>
                  {!!errors.picture && (
                    <Typography variant="h6" color="red">
                      {i18n.t('promotionBannerCreate.form.upload.required')}
                    </Typography>
                  )}
                  <Typography variant="h6">{i18n.t('promotionBannerCreate.form.upload.subtitle')}</Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <UploadDropzone accept="image/*" maxFiles={1} image={files[0]} onDrop={handleDrop} onRemove={handleRemove} />
                </Box>
              </Box>
              <Box />
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default BannerCreate;
