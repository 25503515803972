import {
  Box,
  Card,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { isValid, parseISO } from 'date-fns';
import { FC, useEffect, useMemo, useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useLoadingContext } from 'src/contexts/LoadingContext';
import { Loyalty } from 'src/types/loyalty';
import { FormLoyaltyData, handleLoyaltyFormSubmit, useLoyaltyTemplate } from '../../actions/loyaltyActions';
import { useAllRestaurants } from '../../actions/restaurantActions';
import useSettings from '../../hooks/useSettings';
import { FormInputNumberDecimal } from '../common/FormInputNumberDecimal';
import { FormInputText } from '../common/FormTextField';
import { UploadDropzone } from '../common/PromotionUploadDropzone';
import { LoyaltyConditionsContext } from './context/LoyaltyConditionsContext';
import { LoyaltyType } from './enum/LoyaltyType';
import { LoyaltyConditions } from './form/LoyaltyConditions';
import { MaxUsages } from './form/MaxUsages';
import { WeekDaySelection } from './form/WeekDaySelection';

type Props = {
  type?: LoyaltyType.PRIVILEGES | LoyaltyType.REWARD | LoyaltyType.BONUS_POINTS;
};

export const LoyaltyDetails: FC<Props> = ({ type }) => {
  const { settings } = useSettings();
  const { i18n } = useTranslation();
  const location = useLocation();
  const { promotionId } = useParams();
  const navigate = useNavigate();
  const { isLoading, setLoading } = useLoadingContext();
  const { data: restaurants } = useAllRestaurants();
  const { data: loyaltyTemplate, mutate } = useLoyaltyTemplate(promotionId, type);

  const methods = useForm<Loyalty>({
    defaultValues: {
      startDate: '',
      endDate: '',
      restaurants: [],
      maxUsages: -1,
      operation: null,
      loyaltyType: LoyaltyType.BONUS_POINTS,
      applyTo: 'customer',
      deletePicture: false,
      conditions: null,
    },
    mode: 'all',
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    register,
    reset,
    trigger,
  } = methods;

  const formMode = location.pathname === '/dashboard/loyalties/create' ? 'createForm' : 'editForm';
  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [displayPreview, setDisplayPreview] = useState<boolean>(formMode === 'editForm');
  const [isSpecificDaySwitchOn, setIsSpecificDaySwitchOn] = useState<boolean>(false);

  const [hasConditions, setHasConditions] = useState<boolean>(!!loyaltyTemplate?.conditions);
  const [hasTimeCondition, setHasTimeCondition] = useState<boolean>(!!loyaltyTemplate?.conditions?.timeConstraint);
  const [hasRestaurantCondition, setHasRestaurantCondition] = useState<boolean>(!!loyaltyTemplate?.conditions?.restaurantConstraint);
  const [hasRestosDollarsCondition, setHasRestosDollarsCondition] = useState<boolean>(
    !!loyaltyTemplate?.conditions?.restoDollarsConstraint,
  );
  const [hasSpecificProductCondition, setHasSpecificProductCondition] = useState<boolean>(!!loyaltyTemplate?.conditions?.productConstraint);

  register('picture', { required: false });
  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const loyaltyType = watch('loyaltyType');
  const operation = watch('operation');
  const restaurantsList = watch('restaurants');
  const applyTo = watch('applyTo');

  const onSubmit: SubmitHandler<Loyalty> = async data => {
    setLoading(true);
    const updatedData: FormLoyaltyData = {
      promotionId,
      formData: data,
      formSwitches: {
        isSpecificDaySwitchOn,
        hasTimeCondition,
        hasRestaurantCondition,
        hasRestosDollarsCondition,
        hasConditions,
        hasSpecificProductCondition,
      },
    };
    const loyalty = await handleLoyaltyFormSubmit(updatedData, formMode);
    setLoading(false);
    await mutate(loyalty);
    goBack();
  };

  const getAdornment = (op: string) => {
    if (op === 'percent')
      return (
        <>
          <InputAdornment position="start">+</InputAdornment>
          <InputAdornment position="end">%</InputAdornment>
        </>
      );

    if (op === 'multiply') return <InputAdornment position="start">x</InputAdornment>;
    return <InputAdornment position="start">+</InputAdornment>;
  };

  const areAllRestaurantsSelected = useMemo(() => {
    if (!restaurantsList) return false;
    return restaurants.every(restaurant => restaurantsList.some(restaurantId => restaurant.id === restaurantId));
  }, [restaurants, restaurantsList]);

  const handleDrop = (newFiles: FileWithPath[]): void => {
    setFiles(() => newFiles);
    setValue('deletePicture', false);
    setValue('picture', newFiles[0], { shouldValidate: true });
  };

  const handleRemove = (): void => {
    setFiles([]);

    setValue('picture', null, { shouldValidate: true });
    setValue('deletePicture', true);
    setDisplayPreview(false);
  };

  useEffect(() => {
    if (loyaltyTemplate) reset(loyaltyTemplate);

    setIsSpecificDaySwitchOn(!!loyaltyTemplate?.repetition);
  }, [reset, loyaltyTemplate]);

  useEffect(() => {
    setHasConditions(!!loyaltyTemplate?.conditions);
  }, [loyaltyTemplate?.conditions]);

  useEffect(() => {
    setHasTimeCondition(!!loyaltyTemplate?.conditions?.timeConstraint);
  }, [loyaltyTemplate?.conditions?.timeConstraint]);

  useEffect(() => {
    setHasRestaurantCondition(!!loyaltyTemplate?.conditions?.restaurantConstraint);
  }, [loyaltyTemplate?.conditions?.restaurantConstraint]);

  useEffect(() => {
    setHasSpecificProductCondition(!!loyaltyTemplate?.conditions?.productConstraint);
  }, [loyaltyTemplate?.conditions?.productConstraint]);

  useEffect(() => {
    setHasRestosDollarsCondition(!!loyaltyTemplate?.conditions?.restoDollarsConstraint);
  }, [loyaltyTemplate?.conditions?.restoDollarsConstraint]);

  const setApplyTo = (value: 'customer' | 'employee'): void => {
    setHasConditions(y => y && value === 'customer');
    setValue('applyTo', value);
  };

  const goBack = () => {
    switch (type) {
      case LoyaltyType.BONUS_POINTS:
        navigate('/dashboard/loyalties/bonus-points');
        break;
      case LoyaltyType.PRIVILEGES:
        navigate('/dashboard/loyalties/privileges');
        break;
      default:
        navigate('/dashboard/loyalties/privileges');
        break;
    }
  };

  return (
    <FormProvider {...methods}>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 4 }}>
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h3">
                {formMode === 'createForm' ? i18n.t('loyaltyDetails.form.title') : i18n.t('loyaltyDetails.form.editTitle')}
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ height: '100%', display: 'flex', alignItems: 'flex-end' }}>
                <LoadingButton loading={isLoading} sx={{ px: 4 }} color="secondary" variant="outlined" onClick={goBack}>
                  {i18n.t('button.cancel')}
                </LoadingButton>
                <LoadingButton
                  loading={isLoading}
                  sx={{ px: 4, ml: 2 }}
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                  variant="contained">
                  {i18n.t('button.save')}
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Card sx={{ my: 2 }}>
              <Box>
                <Grid container spacing={2} sx={{ padding: '24px' }}>
                  {formMode === 'createForm' && (
                    <>
                      <Grid item xs={3} display="flex" alignItems="center">
                        <Typography color="textPrimary" variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                          {i18n.t('loyaltyDetails.form.loyaltyType')}
                        </Typography>
                      </Grid>
                      <Grid item xs={9} display="flex" alignItems="center">
                        <Controller
                          rules={{ required: true }}
                          name="loyaltyType"
                          control={control}
                          render={({ field }) => (
                            <FormControl error={!!errors.loyaltyType} variant="standard">
                              <RadioGroup
                                data-testid="input-loyaltyType"
                                aria-label={i18n.t('loyaltyDetails.form.loyaltyType')}
                                {...field}
                                row>
                                <FormControlLabel
                                  sx={{ color: errors.loyaltyType ? 'red' : 'inherit' }}
                                  value={LoyaltyType.BONUS_POINTS}
                                  control={<Radio required color="secondary" />}
                                  label={`${i18n.t('loyaltyDetails.form.bonusPoints')}`}
                                />
                                <FormControlLabel
                                  sx={{ color: errors.loyaltyType ? 'red' : 'inherit' }}
                                  value={LoyaltyType.PRIVILEGES}
                                  control={<Radio required color="secondary" />}
                                  label={`${i18n.t('loyaltyDetails.form.privilege')}`}
                                />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={3} display="flex" alignItems="center">
                    <Typography color="textPrimary" variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      {i18n.t('loyaltyDetails.form.applyTo')}
                    </Typography>
                  </Grid>
                  <Grid item xs={9} display="flex" alignItems="center">
                    <Controller
                      rules={{ required: true }}
                      name="applyTo"
                      control={control}
                      render={({ field }) => (
                        <FormControl error={!!errors.applyTo} variant="standard">
                          <RadioGroup
                            aria-label={i18n.t('loyaltyDetails.form.applyTo')}
                            {...field}
                            row
                            onChange={x => setApplyTo(x.target.value as 'customer' | 'employee')}>
                            <FormControlLabel
                              sx={{ color: errors.applyTo ? 'red' : 'inherit' }}
                              value="customer"
                              control={<Radio required color="secondary" />}
                              label={`${i18n.t('loyaltyDetails.form.customer')}`}
                            />
                            <FormControlLabel
                              sx={{ color: errors.applyTo ? 'red' : 'inherit' }}
                              value="employee"
                              control={<Radio required color="secondary" />}
                              label={`${i18n.t('loyaltyDetails.form.employee')}`}
                            />
                          </RadioGroup>
                        </FormControl>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography color="textPrimary" variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      {i18n.t('loyaltyDetails.form.informations')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <FormInputText
                      sx={{ width: '100%' }}
                      label={`${i18n.t('loyaltyDetails.form.title')}`}
                      variant="outlined"
                      control={control}
                      data-testid="input-title"
                      name="title"
                      required
                      error={!!errors.title}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <FormInputText
                      sx={{ width: '100%' }}
                      label={`${i18n.t('loyaltyDetails.form.description')}`}
                      variant="outlined"
                      control={control}
                      name="description"
                      data-testid="input-desc"
                      required
                      error={!!errors.description}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText
                      sx={{ width: '100%' }}
                      label={`${i18n.t('loyaltyDetails.form.termsOfUse')}`}
                      variant="outlined"
                      control={control}
                      data-testid="input-termsOfUse"
                      name="termsOfUse"
                      error={!!errors.termsOfUse}
                    />
                  </Grid>
                </Grid>

                {loyaltyType === LoyaltyType.BONUS_POINTS && (
                  <div>
                    <Divider variant="fullWidth" />
                    <Grid container spacing={2} sx={{ padding: '24px' }}>
                      <Grid item xs={12}>
                        <Typography color="textPrimary" variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                          {i18n.t('loyaltyDetails.form.pointsValue')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          rules={{ required: true }}
                          name="operation"
                          control={control}
                          render={({ field }) => (
                            <FormControl error={!!errors.operation} variant="standard">
                              <RadioGroup data-testid="input-operation" aria-label="operation" {...field} row>
                                <FormControlLabel
                                  sx={{ color: errors.operation ? 'red' : 'inherit' }}
                                  value="add"
                                  control={<Radio color="secondary" />}
                                  label={`${i18n.t('loyaltyDetails.form.addition')}`}
                                />
                                <FormControlLabel
                                  sx={{ color: errors.operation ? 'red' : 'inherit' }}
                                  value="multiply"
                                  control={<Radio color="secondary" />}
                                  label={`${i18n.t('loyaltyDetails.form.multiplication')}`}
                                />
                                <FormControlLabel
                                  sx={{ color: errors.operation ? 'red' : 'inherit' }}
                                  value="percent"
                                  control={<Radio color="secondary" />}
                                  label={`${i18n.t('loyaltyDetails.form.percent')}`}
                                />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <FormInputNumberDecimal
                          min={1.01}
                          sx={{
                            width: '100%',
                            '& input': {
                              order: 2,
                              textAlign: operation === 'percent' ? 'right' : 'left',
                              padding: 0,
                              height: '56px',
                              paddingRight: operation === 'percent' ? 0 : '14px',
                            },
                            '& .MuiInputAdornment-positionStart': {
                              order: '1',
                            },
                            '& .MuiInputAdornment-positionEnd': {
                              order: '3',
                              marginRight: '14px',
                            },
                          }}
                          label={`${i18n.t('loyaltyDetails.form.value')}`}
                          variant="outlined"
                          control={control}
                          name="value"
                          data-testid="input-value"
                          required
                          error={!!errors.value}
                          InputProps={{
                            startAdornment: getAdornment(operation),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                )}

                <Divider variant="fullWidth" />

                <Grid container spacing={2} sx={{ padding: '24px' }}>
                  <Grid item xs={12}>
                    <Typography color="textPrimary" variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      {i18n.t('loyaltyDetails.form.validPeriod')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="startDate"
                      rules={{
                        required: true,
                        validate: value => {
                          const date = typeof value === 'string' ? parseISO(value) : value;
                          return isValid(date);
                        },
                      }}
                      control={control}
                      render={({ field, fieldState }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateTimePicker
                            {...field}
                            sx={{ width: '100%' }}
                            slotProps={{
                              textField: {
                                error: fieldState.invalid,
                                // @ts-ignore
                                'data-testid': 'input-startDate',
                              },
                            }}
                            value={field.value ? new Date(field.value) : null}
                            label={`${i18n.t('loyaltyDetails.form.startDate')}`}
                            maxDate={new Date(endDate)}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="endDate"
                      rules={{
                        required: true,
                        validate: value => {
                          const date = typeof value === 'string' ? parseISO(value) : value;
                          return isValid(date);
                        },
                      }}
                      control={control}
                      render={({ field, fieldState }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateTimePicker
                            {...field}
                            sx={{ width: '100%' }}
                            slotProps={{
                              textField: {
                                error: fieldState.invalid,
                                // @ts-ignore
                                'data-testid': 'input-endDate',
                              },
                            }}
                            value={field.value ? new Date(field.value) : null}
                            label={`${i18n.t('loyaltyDetails.form.endDate')}`}
                            minDate={new Date(startDate)}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  {loyaltyType === LoyaltyType.BONUS_POINTS && (
                    <>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              color="secondary"
                              checked={isSpecificDaySwitchOn}
                              onChange={event => setIsSpecificDaySwitchOn(event.target.checked)}
                            />
                          }
                          label={`${i18n.t('loyaltyDetails.form.specificDay')}`}
                        />
                      </Grid>

                      {isSpecificDaySwitchOn && (
                        <WeekDaySelection
                          validDaysInputName="repetition.validDays"
                          startTimeInputName="repetition.startTime"
                          endTimeInputName="repetition.endTime"
                          sx={{ paddingLeft: '15px', paddingTop: '16px' }}
                        />
                      )}
                    </>
                  )}
                </Grid>

                <Divider variant="fullWidth" />

                <Grid container spacing={2} sx={{ padding: '24px' }}>
                  <Grid item xs={12}>
                    <Typography color="textPrimary" variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      {i18n.t('loyaltyDetails.form.validRestaurants')}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <FormGroup row>
                      <FormControlLabel
                        label={`${i18n.t('loyaltyDetails.form.allRestaurants')}`}
                        control={
                          <Checkbox
                            color="secondary"
                            checked={areAllRestaurantsSelected}
                            onChange={(event, checked) => {
                              if (checked) {
                                setValue(
                                  'restaurants',
                                  restaurants.map(restaurant => restaurant.id),
                                );
                              } else {
                                setValue('restaurants', []);
                              }
                              trigger();
                            }}
                          />
                        }
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={10}>
                    <FormControl component="fieldset" variant="standard">
                      <FormGroup row>
                        <Controller
                          name="restaurants"
                          rules={{ required: true }}
                          control={control}
                          render={({ field, fieldState }) => (
                            <>
                              {restaurants.map(r => (
                                <FormControlLabel
                                  key={r.id}
                                  label={r.name}
                                  sx={{ color: fieldState.invalid ? '#f44336' : '' }}
                                  control={
                                    <Checkbox
                                      color={fieldState.invalid ? 'error' : 'secondary'}
                                      value={r.id}
                                      checked={field.value.some(existingValue => existingValue === r.id)}
                                      onChange={(event, checked) => {
                                        if (checked) {
                                          field.onChange([...field.value, event.target.value]);
                                        } else {
                                          field.onChange(field.value.filter(value => value !== event.target.value));
                                        }
                                      }}
                                    />
                                  }
                                />
                              ))}
                            </>
                          )}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </Grid>

                <Divider variant="fullWidth" />

                <Grid container spacing={2} sx={{ padding: '24px' }}>
                  <Grid item xs={12}>
                    <Typography color="textPrimary" variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      {`${i18n.t('loyaltyDetails.form.usageNumber')}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <MaxUsages useFormMethods={methods} hasConditions={hasConditions} />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography color="textPrimary" variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      {`${i18n.t('loyaltyDetails.form.connexion')}`}
                    </Typography>
                  </Grid>
                  {loyaltyType !== LoyaltyType.BONUS_POINTS && (
                    <>
                      <Grid item xs={6}>
                        <FormInputText
                          sx={{ width: '100%' }}
                          label={`${i18n.t('loyaltyDetails.form.mdCode')}`}
                          variant="outlined"
                          control={control}
                          name="mdCode"
                          error={!!errors.mdCode}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={loyaltyType !== LoyaltyType.BONUS_POINTS ? 6 : 12}>
                    <FormInputText
                      sx={{ width: '100%' }}
                      label={`${i18n.t('loyaltyDetails.form.mdPlu')}`}
                      variant="outlined"
                      control={control}
                      name="mdPlu"
                      error={!!errors.mdPlu}
                    />
                  </Grid>
                </Grid>

                <Divider variant="fullWidth" />

                <Grid container spacing={2} sx={{ padding: '24px' }}>
                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Typography color="textPrimary" variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                          {i18n.t('loyaltyDetails.form.upload.title')}
                        </Typography>
                        <Typography variant="h6">{i18n.t('loyaltyDetails.form.upload.subtitle')}</Typography>
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <UploadDropzone
                          accept="image/*"
                          maxFiles={1}
                          image={files[0]}
                          imageUrl={displayPreview ? loyaltyTemplate?.image : undefined}
                          onDrop={handleDrop}
                          onRemove={handleRemove}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Card>

            <LoyaltyConditionsContext.Provider
              value={{
                applyTo,
                hasConditions,
                setHasConditions,
                hasTimeCondition,
                setHasTimeCondition,
                hasRestaurantCondition,
                setHasRestaurantCondition,
                hasRestosDollarsCondition,
                setHasRestosDollarsCondition,
                hasSpecificProductCondition,
                setHasSpecificProductCondition,
              }}>
              <LoyaltyConditions />
            </LoyaltyConditionsContext.Provider>
          </Box>
        </Container>
      </Box>
    </FormProvider>
  );
};
