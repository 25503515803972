import { WeekDays } from '../constants';

export const enTranslation = {
  addPointsModal: {
    title: 'Adjust points',
    actualScore: 'Actual score',
    addedPoints: 'Added points',
    removedPoints: 'Removed points',
    total: 'Total',
  },
  applicationTitle: 'Plaisirs & Privilèges',
  weekDays: {
    [WeekDays.SUNDAY]: 'Sunday',
    [WeekDays.MONDAY]: 'Monday',
    [WeekDays.TUESDAY]: 'Tuesday',
    [WeekDays.WEDNESDAY]: 'Wednesday',
    [WeekDays.THURSDAY]: 'Thursday',
    [WeekDays.FRIDAY]: 'Friday',
    [WeekDays.SATURDAY]: 'Saturday',
  },
  button: {
    add: 'Add',
    cancel: 'Cancel',
    close: 'Close',
    confirm: 'Confirm',
    confirmMigration: 'Confirm migration',
    continue: 'Continue',
    create: 'Create',
    delete: 'Delete',
    edit: 'Edit',
    migrate: 'Migrate',
    remove: 'Remove',
    save: 'Save',
    activateAccount: 'Activate Account',
    deactivateAccount: 'Deactivate Account',
  },

  common: {
    dashboard: 'Dashboard',
    noPicture: 'No picture',
  },
  promotionBannerCreate: {
    title: 'New Banner',
    listTitle: 'Banners',
    breadCrumb: {
      title: 'New Banner',
    },
    form: {
      description: 'Description',
      nameInputLabel: 'Name',
      position: 'Position',
      URLInputLabel: 'URL',
      upload: {
        title: 'Upload Image',
        subtitle: 'Image ratio 111x131 recommended',
        required: 'Image required',
      },
      instruction: {
        main: 'Select image',
        secondary: 'Drop image or browse through your computer',
      },
    },
  },
  promotionBannerList: {
    createButton: 'Create',
    listTitle: 'Banners',
    title: 'Banners',
    name: 'Name',
    url: 'URL',
    actions: 'Actions',
    breadCrumb: {
      title: 'Banners',
    },
    dialogDelete: {
      title: 'Delete: {{name}}',
      description: 'Are you sure you want to delete this promotion?',
      no: 'No',
      yes: 'Yes',
    },
  },
  loyaltyList: {
    createButton: 'Create',
    listTitle: 'Rewards & Privileges',
    title: 'Rewards & Privileges',
    privilegeName: 'Privilege Name',
    startDate: 'Start Date',
    endDate: 'End Date',
    type: 'Type',
    restaurant: 'Restaurant',
    actions: 'Actions',
    status: 'Status',
    upcoming: 'Upcoming',
    inProgress: 'In progress',
    expired: 'Expired',
    missingInfos: 'Missing infos',
    tabs: {
      all: 'All',
      privileges: 'Rebate',
      bonusPoints: 'Bonus Points',
      rewards: 'Rewards',
    },
    reward: 'Reward',
    dialogDelete: {
      title: 'Delete: {{name}}',
      description: 'Are you sure you want to delete this promotion?',
      no: 'No',
      yes: 'Yes',
    },
  },
  loyaltyDetails: {
    tabs: {
      privilege: 'Privilege',
      reward: 'Reward',
      bonusPoints: 'Bonus Points',
      promoCode: 'Promo Code',
    },
    form: {
      upload: {
        title: 'Image',
        subtitle: 'Image ratio 168x95 recommended',
      },
      availability: 'Availability',
      validFor: 'Valid for',
      availabilityText:
        'Please note that the reward will always be validated on the night following the scan. Therefore, the reward will only be available the next day, never on the same day',
      conditions: 'Conditions',
      lockPrivilege: 'Lock promotion',
      connexion: 'Connection',
      usage: 'usage(s)',
      startTime: 'Start time',
      endTime: 'End time',
      specificDay: 'Specific day/time',
      specificRestaurants: 'Specific restaurant',
      specificProduct: 'Purchase of a specific product',
      useOfRestoDollar: 'Use of resto dollar',
      minimumValue: 'Minimum value',
      addition: 'Addition',
      multiplication: 'Multiplication',
      percent: 'Percentage',
      value: 'Value',
      validPeriod: 'Valid periods',
      title: 'New Promotion',
      pointsValue: 'Points value',
      editTitle: 'Edit Promotion',
      loyaltyType: 'Loyalty Type',
      reward: 'Reward',
      privilege: 'Rebate',
      bonusPoints: 'Bonus Points',
      informations: 'Informations',
      privilegeName: 'Privilege Name',
      description: 'Description',
      startDate: 'Start Date',
      endDate: 'End Date',
      termsOfUse: 'Usage Policy',
      validRestaurants: 'Valid Restaurants',
      allRestaurants: 'All Restaurants',
      mdCode: 'MD Code',
      mdPlu: 'MD PLU',
      usageNumber: 'Usage Number',
      persistent: 'Persistent',
      unique: 'Unique',
      stackable: 'Stackable',
      employee: 'Employee',
      customer: 'Customer',
      applyTo: 'Apply To',
      maxUsageDisabled: 'A locked promotion can be used only once',
      atLeastOneCondition: 'Please select at least one condition',
    },
  },
  invoiceScanDetails: {
    breadcrumbs: {
      dashboard: 'Dashboard',
      scans: 'Invoice Scans',
      details: 'Invoice Details',
    },
    deleteInvoice: 'Delete Invoice',
    deleteInvoice_number: 'Delete Invoice #{{number}}',
    deleteInvoiceWarning: 'Are you sure you want to delete this invoice scan? This action is irreversible.',
    invoice_number: 'Invoice #{{number}}',
    title: 'Invoice Details',
  },
  invoiceScanList: {
    actions: 'Actions',
    breadCrumb: {
      scans: 'Scans',
    },
    date: 'Invoice date',
    email: 'Email',
    invoiceNumber: 'Invoice number',
    listTitle: 'Invoice scans',
    bonusPoints: 'Bonus Points',
    restaurant: 'Restaurant',
    result: 'Result',
    scanMethod: 'Scan method',
    title: 'Invoice list',
    status: {
      alreadyScanned: 'Already scanned',
      invalidInvoice: 'Invalid',
      invoiceScan: 'In validation',
      invoiceScanDeleted: 'Deleted',
      notFound: 'Not found',
      notPaid: 'Not paid',
      outdatedInvoice: 'Outdated',
      pointsAdded: 'Points added',
      validInvoice: 'Valid',
      wrongPrice: 'Wrong price',
      wrongRestaurant: 'Wrong restaurant',
    },
  },
  participant: {
    addPoints: 'Adjust points',
    phoneNumber: 'Phone number',
    breadCrumb: {
      detail: 'Participant',
      list: 'Participants',
    },
    emailAndPhoneNumber: 'Email and phone number',
    creation: 'Creation',
    destination: 'Destination',
    email: 'Email',
    firstLastNameAndEmail: 'Email, Firstname and Lastname',
    invalidScans: 'Invalid Scans',
    lastScan: 'Last scan',
    listTitle: 'Participants',
    migratedTo: 'Migrated to',
    migrateUser: 'Migrate user',
    participantDetails: 'Participant details',
    reward: {
      actions: 'Actions',
      description: 'Description',
      image: 'Image',
      name: 'Name',
      value: 'Value',
      dialogDelete: {
        title: 'Delete: {{name}}',
        description: 'Are you sure you want to delete this reward?',
        no: 'No',
        yes: 'Yes',
        refundPoints: 'Refund reward points ({{points}} points)',
      },
    },
    score: 'Score',
    selectDestination: 'Select destination',
    source: 'Source',
    status: 'Status',
    validScans: 'Valid scans',
    migrationWarning:
      'By clicking on the «Migrate» button, all the points and transaction history of the source account will be transferred to the destination account. This action is irreversible.',
    tabs: {
      details: 'Details',
      transactions: 'Transactions',
      wallet: 'Wallet',
    },
    wallet: 'Wallet',
    confirmToggleActive: 'Are you sure you want to make this user active ?',
    titleConfirmActiveToggleModal: 'Activate user.',
    confirmToggleDeactivate: 'Are you sure you want to make this user inactive ?',
    titleConfirmDeactivateToggleModal: 'Deactivate user.',
  },
  participantList: {
    actions: 'Actions',
    identification: 'Identification',
    invalidInvoices: 'Invalid Invoices',
    lastScanDate: 'Last scan date',
    pointsTotal: 'Points total',
    status: 'Status',
    validInvoices: 'Valid Invoices',
    flaggedForDeletionAt: 'Deletion in progress',
    inactive: 'Inactive',
    active: 'Active',
  },
  searchBar: {
    search: 'Search by',
  },
  transactions: {
    alreadyScanned: 'Invalid scan | Already scanned',
    details: {
      invoiceNumber: 'Invoice number',
      total: 'Total',
      restaurant: 'Restaurant',
      reward: 'Reward',
      type: 'Transaction type',
      diffPoints: 'Points Diff.',
      totalPoints: 'Total points',
      date: 'Date',
    },
    expiredReward: 'Expired reward',
    invalid: 'Invalid',
    invalidScan: 'Invalid scan',
    invoiceScan: 'Validating scan',
    purchasedReward: 'Reward bought',
    removedReward: 'Reward removed',
    usedReward: 'Used Reward',
    validScan: 'Valid scan',
    invoiceScanDeleted: 'Deleted invoice-scan',
    pointsAdded: 'Points added by administration',
    pointsUsed: 'Used Reward',
    restosDollarsRefund: 'Refunded restos dollars in points',
  },
};
